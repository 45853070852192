body {
  margin: 0;
}

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.app {
  padding-top: 60px;
  padding-bottom: 40px;
}

.header,
.footer {
  display: flex;
  width: 100%;
  position: fixed;
  padding: 10px;
}

.header {
  top: 0;
}

.navbar,
.nav {
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar {
  align-items: center;
  width: 100%;
}

.nav li {
  display: block;
  flex: 0 1 auto;
  list-style-type: none;
  padding: 0 10px;
}

.content {
  padding: 10px;
}

.products,
.ingredients,
.ingredient-groups {
  display: flex;
}

.products .products-list,
.ingredients .ingredients-list,
.ingredient-groups .ingredient-groups-list {
  margin: 0 2vw 0 0;
  padding: 0;
  list-style: none;
}

.products .products-list li,
.ingredients .ingredients-list li,
.ingredient-groups .ingredient-groups-list li {
  padding: 2px;
}

.products .products-list li:hover,
.ingredients .ingredients-list li:hover,
.ingredient-groups .ingredient-groups-list li:hover {
  background-color: rgb(234, 234, 234);
}

.add-product,
.add-ingredient,
.add-ingredient-group,
.product-details,
.ingredient-details,
.ingredient-group-details {
  margin: 0 0 0 0.3rem;
}

.footer {
  bottom: 0;
}
