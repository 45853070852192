* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header {
  background: rgb(51, 51, 51);
  color: rgb(255, 255, 255);
  font-size: 1.2rem;
}

.header a {
  font-size: 1.3rem;
  color: rgb(255, 255, 255);
}

.header .nav a {
  font-size: 1rem;
}

.product-name {
  font-weight: bold;
}

.article-nr {
  font-size: 0.6rem;
  font-weight: normal;
}

.footer {
  background: rgb(240, 240, 240);
  color: rgb(66, 66, 66);
  font-size: 0.7rem;
}

.border {
  border: 2px solid black;
}

[contenteditable] {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  padding: 1px 4px;
  margin: -1px -4px;
  text-decoration: underline;
  text-decoration-color: rgb(192, 192, 192);
  text-decoration-style: dashed;
  cursor: text;
}

[contenteditable] br {
  display: none;
}

[contenteditable] * {
  display: inline;
  white-space: nowrap;
}

[contenteditable]:focus {
  background: rgb(255, 253, 234);
  text-decoration: none;
}

[data-placeholder]:empty:before {
  content: attr(data-placeholder);
  color: rgb(200, 200, 200);
  font-weight: normal;
}

form > div,
.product-details > div,
.ingredient-details > div,
.ingredient-group-details > div {
  margin-bottom: 0.5rem;
}

.relations-manager .current-items,
.relations-manager .search-items {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.relations-manager .current-items li,
.relations-manager .search-items li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.1rem;
  padding: 0.25rem;
  font-size: 0.85rem;
}

.relations-manager .search-items li:hover {
  background-color: rgb(231, 231, 231);
}

.relations-manager .current-items li {
  background-color: rgb(238, 252, 226);
  border-radius: 0.3rem;
}

.relations-manager .current-items .item-name,
.relations-manager .search-items .item-name {
  width: 100%;
}

.relations-manager .current-items .item-description,
.relations-manager .search-items .item-description {
  font-size: 0.75rem;
}

.relations-manager .item-groups {
  font-size: 0.7rem;
  margin: 0.2rem 0;
}

.relations-manager .search-items .item-group,
.relations-manager .current-items .item-group {
  border-radius: 0.3rem;
  background-color: rgb(216, 241, 199);
  margin: 0.1rem;
  padding: 0.1rem 0.3rem;
}

.relations-manager .search-items .item-group {
  background-color: rgb(239, 239, 239);
}

.metadata {
  margin: 0.7rem auto;
  padding: 0.4rem 0;
  border-top: 1px solid rgb(0, 0, 0);
  font-size: 0.7rem;
}
